import React from 'react';
import { vimeo } from '../../hocks/hocks'
import { backend } from '../../hocks/hocks'
import { VideoBoxList } from '../../components/box/boxList'
import { TitlePaper } from '../../components/utils/utils';

class Playlists extends React.Component {

    state = {
        contents: null,
        length: 0,
        unlockCount: 0,
    }

    componentDidMount() {
        this.fetchChapter()
        this.fetchPlaylists()
    }

    checkAuth() {
        const token = localStorage.getItem('token');
        if (token === null || token === undefined) {
            localStorage.removeItem('username')
            this.props.history.push('/login')
        }
    }

    async getUnlockCount() {
        const token = localStorage.getItem('token')
        const username = localStorage.getItem('username')
        if (token === null || token === undefined) {
            localStorage.removeItem('username')
            this.props.history.push('/login')
        }
        backend.get('api/chapters/opened/?username=' + username, { headers: { 'Authorization': `JWT ${token}` } })
            .then(res => {
                const len = res.data.length
                this.setState({ unlockCount: len })
            })
            .catch(err => {
                console.log(err)
                if (err.response.status === 401) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('username')
                    this.props.history.push('/login')
                }
            })
    }


    fetchChapter() {
        this.getUnlockCount()
            .then(res => {
                const token = localStorage.getItem('token')
                const username = localStorage.getItem('username')
                if (token === null || token === undefined) {
                    localStorage.removeItem('username')
                    this.props.history.push('/login')
                }
                backend.get('api/chapters/?username=' + username, { headers: { 'Authorization': `JWT ${token}` } })
                    .then(res => {
                        const data = res.data.length
                        this.setState({ length: data })
                    })
                    .catch(err => {
                        console.log(err)
                        if (err.response.status === 401) {
                            localStorage.removeItem('token')
                            localStorage.removeItem('username')
                            this.props.history.push('/login')
                        }
                    })
            })
    }

    fetchPlaylists() {
        this.getUnlockCount()
            .then(res => {

                const user_id = process.env.REACT_APP_VIMEO_USER_ID
                const key = process.env.REACT_APP_VIMEO_KEY
                this.checkAuth()
                vimeo.get('users/' + user_id + '/projects/', { headers: { 'Authorization': 'bearer ' + key } })
                    .then(res => {
                        const data = res.data.data.slice(0, this.state.length).map(item => {
                            const uri = item.uri.split('/');
                            const id = uri[uri.length - 1]
                            return { id: id, users: uri[2], text: item.name.slice(2) }
                        })
                        this.setState({ contents: <VideoBoxList to='videos' items={data} unlockCount={this.state.unlockCount} /> })
                    })
                    .catch(err => console.log(err))
            })
    }

    render() {
        return (
            <div>
                <TitlePaper>
                    🎥‍ 試験対策動画 👩‍🏫
                </TitlePaper>
                {
                    this.state.unlockCount === 0 ? this.componentDidMount() : undefined
                }
                {this.state.contents}
            </div>
        )
    }
}

export default Playlists