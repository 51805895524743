import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import classes from './App.module.css';
import AppBar from '../components/appBar/appBar';
import HomePage from './home/home';
import Chapters from './chapters/chapters';
import Parts from './parts/parts';
import Question from './questions/questions';
import Test from './tests/tests/tests';
import TestQuestion from './tests/test_questions/test_questions';
import Playlists from './playlists/playlists'
import Videos from './videos/videos';
import Profile from './profile/profile'
import ChangePwd from './chagePwd/changePwd';
import LoginForm from './login/login';
import ForgotPwd from './forgotPwd/forgotPwd'
import ResetPassword from './resetpw/resetpw';
import LoginForSignup from './signup/loginForSignup';
import Signup from './signup/signup';
import Expired from './signup/expired/expired';

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: '#1976d2',
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#11cb5f',
    },
    green: {
      main: '#00838f',
    },
  },
});

function App() {
  return (
    <div className={classes.App}>
      <BrowserRouter>
        <AppBar />
        <ThemeProvider theme={theme}>
          <Route exact path='/' component={HomePage} />
          <Route exact path='/Chapters' component={Chapters} />
          <Route exact path='/parts/:id' component={Parts} />
          <Route exact path='/questions/:id' component={Question} />
          <Route exact path='/playlists' component={Playlists} />
          <Route exact path='/videos/:users/:id' component={Videos} />
          <Route exact path='/profile' component={Profile} />
          <Route exact path='/tests' component={Test} />
          <Route exact path='/test_questions/:id' component={TestQuestion} />
          <Route exact path='/changePwd' component={ChangePwd} />
          <Route exact path='/login' component={LoginForm} />
          <Route exact path='/forgotpw' component={ForgotPwd} />
          <Route exact path='/resetpw/:token' component={ResetPassword} />
          <Route exact path='/loginSignup/:token' component={LoginForSignup} />
          <Route exact path='/signup/:token/:jwt/:school' component={Signup} />
          <Route exact path='/expired' component={Expired} />
        </ThemeProvider>
      </BrowserRouter>
    </div>

  );
}



export default App;
