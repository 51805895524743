import React from 'react';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import classes from './question.module.css';
import Result, { postLog } from '../../../components/result/result';
import ChoiceBar from './choiceBar/choiceBar';
import MobileStepper from '@material-ui/core/MobileStepper';
import Answer from '../answer';

class ThreeChoice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            answers: [],
            num: 0,
            num_correct: 0,
            is_answer: false,
            is_last: false,
            is_result: false,
        }
        this.handleNext = this.handleNext.bind(this);
        this.handleAgain = this.handleAgain.bind(this);
        this.handleChoice = this.handleChoice.bind(this);
        this.handlePaperContent = this.handlePaperContent.bind(this);
    }


    handleChoice(choice) {
        if (!this.state.is_last) {
            let answer = this.state.answers.slice();
            let is_correct = 0;
            answer.push(choice);
            if (choice === this.props.questions[this.state.num].question_ans) {
                is_correct = 1
            }
            this.setState({
                num: this.state.num + 1,
                answers: answer,
                is_answer: true,
                inputs: '',
                is_last: this.state.num === this.props.questions.length - 2,
                num_correct: this.state.num_correct + is_correct
            })
        } else {
            postLog(this.props.questions, this.state.answers)
            this.setState({ is_result: true });
        }
    }

    handlePaperContent() {
        if (this.state.is_answer) {
            return (
                <div>
                    <Answer answer={this.props.questions[this.state.num - 1].question_ans}
                        userAnswer={this.state.answers[this.state.num - 1]}
                        question_text={this.props.questions[this.state.num - 1].question_text}
                    />
                    <Button variant="contained"
                        color="primary"
                        onClick={this.handleNext}
                        disableElevation>
                        次へ
                </Button>
                </div>
            );
        } else {
            return (
                <div>
                    <p>{this.props.questions[this.state.num].part.question_type.sentence}</p>
                    <p>{this.props.questions[this.state.num].question_text}</p>
                    <div className={classes.Next}>
                        <ChoiceBar onClick={() => this.handleChoice(this.props.questions[this.state.num].choice_1)}
                            text={this.props.questions[this.state.num].choice_1}
                            type='1' />
                        <ChoiceBar onClick={() => this.handleChoice(this.props.questions[this.state.num].choice_2)}
                            text={this.props.questions[this.state.num].choice_2}
                            type='2' />
                        <ChoiceBar onClick={() => this.handleChoice(this.props.questions[this.state.num].choice_3)}
                            text={this.props.questions[this.state.num].choice_3}
                            type='3' />
                    </div>
                </div>
            )
        }
    }

    handleNext() {
        this.setState({
            is_answer: false,
        })
    }

    handleAgain() {
        this.setState({
            nputs: '',
            answers: [],
            num: 0,
            num_correct: 0,
            is_answer: false,
            is_last: false,
            is_result: false,

        })
    }

    progressBar() {
        return (
            <Paper className={classes.WrapperProgressBar}>
                <div className={classes.Center}>
                    <div className={classes.Emoji}>🏃‍♀</div>
                    <div className={classes.Emoji}>
                        <MobileStepper
                            variant="progress"
                            steps={10}
                            position="static"
                            activeStep={this.state.num}
                            className={classes.ProgressBar}
                        />
                    </div>
                    <div className={classes.Emoji}>🚩</div>
                </div>
            </Paper>
        )
    }

    render() {
        let content = (
            <Paper variant="outlined" className={classes.Paper} >
                {this.handlePaperContent()}
            </Paper>
        );

        if (this.state.is_result) {
            const accuracy = this.state.num_correct / this.props.questions.length * 100
            content = (
                <Result accuracy={accuracy}>
                    <div className={classes.Buttons}>
                        <Link to='/' >
                            <Button
                                className={classes.HomeButton}
                                variant="contained"
                                color="primary"
                                disableElevation>
                                Home
                            </Button>
                        </Link>
                        <Button
                            className={classes.HomeButton}
                            variant="contained"
                            color="primary"
                            onClick={this.handleAgain}
                            disableElevation>
                            もう一度
                        </Button>
                    </div>
                </Result>);
        }

        return (
            <div className={classes.Root}>
                {this.progressBar()}
                {content}
            </div>
        );
    }
}

export default ThreeChoice;