import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import classes from './expired.module.css';

const Expired = () => {
    return (
        <div className={classes.Root}>
            <p>セッションの有効期限が切れています</p>
            <Link to='/login' className={classes.Link}>
                <Button variant="contained"
                    className={classes.Button}
                    color="primary"
                    disableElevation>
                    Login画面へ 
                </Button>
            </Link>
        </div>
    );
}

export default Expired;