import React from 'react';
import { backend } from '../../../hocks/hocks'
import Button from '@material-ui/core/Button';

import { failPage, passPage } from '../result/result'
import classes from './test_questions.module.css';
import FiveQuestion from '../fiveQuestion/fiveQuestion';

class TestQuestion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            border: 0,
            score: 0,
            contents: null,
            answers: null,
            user_answers: null,
            result: null,
        }
        this.onChange = this.onChange.bind(this);
        this.onClick = this.onClick.bind(this);
        this.fetchTestQuestions = this.fetchTestQuestions.bind(this);
        this.getBorder = this.getBorder.bind(this);

    }

    componentDidMount() {
        this.fetchTestQuestions()
        this.getBorder()
    }

    onChange(data) {
        // data {answer: string, index: int}
        let ans = this.state.user_answers
        ans[data.index] = data.answer
        this.setState({
            contents: this.state.contents,
            answers: this.state.answers,
            user_answers: ans,
        })
    }

    fiveChoice(question) {
        return (
            <FiveQuestion question={question} onChange={this.onChange} />
        );
    }

    async getBorder() {
        const token = localStorage.getItem('token');
        if (token === null || token === undefined) {
            localStorage.removeItem('username')
            this.props.history.push('/login')
        }

        const border = await backend.get('api/tests/?chapter=' + this.props.match.params.id,
            { headers: { 'Authorization': `JWT ${token}` } })
            .then(res => {
                this.setState({ border: res.data[0].passing })
            })
    }


    fetchTestQuestions() {
        const token = localStorage.getItem('token');
        if (token === null || token === undefined) {
            localStorage.removeItem('username')
            this.props.history.push('/login')
        }

        backend.get('api/test_questions/questions/?id=' + this.props.match.params.id,
            { headers: { 'Authorization': `JWT ${token}` } })
            .then(res => {
                const components = res.data.map(item => {
                    return this.fiveChoice(item)
                })
                const answers = res.data.map(item => {
                    return item.question_ans
                })
                const user_answers = res.data.map(item => {
                    return null
                })
                this.setState({
                    contents: components,
                    answers: answers,
                    user_answers: user_answers,

                })
            })
            .catch(err => {
                if (err.response.status === 401) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('username')
                    this.props.history.push('/login')
                }
                console.log(err)
            })
    }

    handleFail(score) {
        const token = localStorage.getItem('token');
        const username = localStorage.getItem('username');
        if (token === null || token === undefined) {
            localStorage.removeItem('username');
            this.props.history.push('/login');
        }
        backend.get('users/profile/send_fail_message/?username=' + username + '&score=' + score + '&chapter=' + this.props.match.params.id,
            { headers: { 'Authorization': `JWT ${token}` } })
            .then(res => {
                console.log(res.data)
            })
    }

    handlePass(score) {
        const token = localStorage.getItem('token');
        const username = localStorage.getItem('username');
        if (token === null || token === undefined) {
            localStorage.removeItem('username');
            this.props.history.push('/login');
        }
        backend.get('users/profile/passed_test/?username=' + username + '&score=' + score + '&chapter=' + this.props.match.params.id,
            { headers: { 'Authorization': `JWT ${token}` } })
            .then(res => {
                console.log(res.data)
            })
    }

    onClick(e) {
        let acc = calculate_acc(this.state.answers, this.state.user_answers)
        if (acc < this.state.border) {
            this.setState({
                result: 'fail',
                score: acc
            })
            this.handleFail(acc)
        } else {
            this.setState({
                result: 'pass',
                score: acc
            })
            this.handlePass(acc)
        }
    }

    render() {
        if (this.state.result === null) {
            return (
                <div className={classes.Root}>
                    {this.state.contents}
                    <Button variant="contained"
                        className={classes.SubmitButton}
                        onClick={this.onClick}
                        color="primary"
                        disableElevation>
                        提出
                    </Button>
                </div >
            )
        }
        if (this.state.result === 'fail') {
            return (
                <div className={classes.Root}>
                    {failPage(this.state.score, this.state.border)}
                </div>
            );
        } else {
            return (
                <div className={classes.Root}>
                    {passPage(this.state.score, this.state.border)}
                </div>
            )
        }
    }
}

export default TestQuestion

const calculate_acc = (answers, corrects) => {
    let acc = 0
    console.log('answers:', answers)
    console.log('correct:', corrects)
    for (let i = 0; i < answers.length; i++) {
        if (answers[i] === corrects[i]) {
            acc = acc + 1
        }
    }
    console.log("num_correct:", acc)
    console.log("answer_len:", answers.length)
    return acc / answers.length
}
