import React from 'react';
import { backend } from '../../../hocks/hocks'
import { TestBoxList } from '../../../components/box/boxList'
import classes from './tests.module.css'
import { TitlePaper } from '../../../components/utils/utils';



class Test extends React.Component {

    state = {
        contents: null,
        unlockCount: 0,
    }

    componentDidMount() {
        this.fetchTest()
    }

    async getUnlockCount() {
        const token = localStorage.getItem('token')
        const username = localStorage.getItem('username')
        if (token === null || token === undefined) {
            localStorage.removeItem('username')
            this.props.history.push('/login')
        }
        backend.get('api/chapters/opened/?username=' + username, { headers: { 'Authorization': `JWT ${token}` } })
            .then(res => {
                const len = res.data.length
                console.log(len)
                this.setState({ unlockCount: len })
            })
            .catch(err => {
                console.log(err)
                if (err.response.status === 401) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('username')
                    this.props.history.push('/login')
                }
            })
    }

    fetchTest() {
        const token = localStorage.getItem('token')
        const username = localStorage.getItem('username')

        if (token === null || token === undefined) {
            localStorage.removeItem('username')
            this.props.history.push('/login')
        }
        this.getUnlockCount()
            .then(res => {
                backend.get('api/chapters/?username=' + username, { headers: { 'Authorization': `JWT ${token}` } })
                    .then(res => {
                        const data = res.data.map(item => {
                            return { id: item.id, text: item.chapter_title }
                        })
                        this.setState({ contents: <TestBoxList to='test_questions' items={data} unlockCount={this.state.unlockCount} /> })
                    })
                    .catch(err => {
                        console.log(err)
                        if (err.response.status === 401) {
                            localStorage.removeItem('token')
                            localStorage.removeItem('username')
                            this.props.history.push('/login')
                        }
                    })
            })
    }

    render() {
        return (
            <div className={classes.Root}>
                <TitlePaper>
                    💯  確認テスト 💯
                </TitlePaper>
                {
                    this.state.unlockCount === 0 ? this.componentDidMount() : undefined
                }
                {this.state.contents}
            </div>
        )
    }
}

export default Test