import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import classes from './loginForSignup.module.css'

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY
// localhost:3000/loginSignup/wh*43r6r43ee7lduhy66yo9e3y8zy0shk60w51m3t1
class LoginForSignup extends React.Component {

	constructor(props) {
		// console.log(SECRET_KEY)
		super(props);
		if (this.props.match.params.token !== SECRET_KEY) {
			this.props.history.push('/expired')
		}

		this.state = {
			username: '',
			password: '',
			message: '登録用アカウントのユーザネーム、パスワードを入力してください。',
		}

		this.handleChangeUsername = this.handleChangeUsername.bind(this);
		this.handleChangePassword = this.handleChangePassword.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.showMessage = this.showMessage.bind(this);
		this.onKeyPress = this.onKeyPress.bind(this);
	}

	// handle the input of username
	handleChangeUsername(event) {
		this.setState({
			username: event.target.value,
		})
	}

	// handle the input of password
	handleChangePassword(event) {
		this.setState({
			password: event.target.value,
		})
	}


	onKeyPress(event) {
		// if keypress is Enter key ...
		if (event.key === 'Enter') { this.handleSubmit(event) }
	}

	handleSubmit(event) {
		// prevent default
		event.preventDefault()
		// fetch api for getting jwt token.
		const data = getJWT(this.state)
		data.then(res => {
			const token = res.token
			if (token === undefined) {
				// Process for bad login
				const message = 'ユーザネームまたはパスワードが間違っています。'
				this.setState({
					password: '',
					message: message,
				})
			} else {
				localStorage.setItem('token', token)
				const user = getUser(this.state.username)
				user.then(res2 => {
					// /signup/:token/:jwt/:school
					this.props.history.push('/signup/' + this.props.match.params.token + '/' + res.token + '/' + res2[0].school)
				})
			}
		})
	}


	showMessage() {
		return <p className={classes.Message}>{this.state.message}</p>
	}


	render() {
		return (
			<div className={classes.Root}>
				{this.showMessage()}
				<h2>ユーザ登録</h2>
				<div className={classes.Item}>
					<TextField className={classes.TextField}
						value={this.state.username}
						onKeyPress={this.onKeyPress}
						onChange={this.handleChangeUsername}
						label="Username" /></div>
				<div className={classes.Item}>
					<TextField className={classes.TextField}
						value={this.state.password}
						type="password"
						onKeyPress={this.onKeyPress}
						onChange={this.handleChangePassword}
						label="Password" /></div>
				<div className={classes.Item}>
					<Button variant="contained"
						className={classes.Button}
						color="primary"
						onClick={this.handleSubmit}
						disableElevation>
						Login
                    </Button></div>
			</div>
		)
	}
}


const getJWT = async (data) => {
	const body = {
		username: data.username,
		password: data.password,
	}
	const url = process.env.REACT_APP_BACKEND_BASE_URL + 'api/auth/'
	const res = await fetch(url, {
		method: 'POST',
		mode: 'cors',
		credentials: 'same-origin',
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		},
		redirect: 'follow',
		referrerPlolicy: 'no-referrer',
		body: JSON.stringify(body)
	})
	const json = await res.json()
	return json
}


const getUser = async (username) => {
	const url = process.env.REACT_APP_BACKEND_BASE_URL + 'users/user/?username=' + username // Profile API
	const token = localStorage.getItem('token') // JWT token
	const res = await fetch(url, {
		method: 'GET',
		mode: 'cors',
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
			'Authorization': 'JWT ' + token,
		},
	})
	let user = await res.json()
	console.log(res)
	console.log(user)
	return user
}


export default LoginForSignup;