import React from 'react';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import classes from './forgotPwd.module.css';

// An error with backend server.
// forgotPwd.js:59 POST https://back-dot-sakura-kaisgo-app.an.r.apppot.com/api/password_reset/reset_password/ 500

export default class ForgotPassword extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
            email: '',
            message: '',
            success: false,
		}
        this.handleEmail = this.handleEmail.bind(this);
        this.handlePost = this.handlePost.bind(this);
        this.postEmail = this.postEmail.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
	}


	handleEmail(event) {
		this.setState({
			email: event.target.value,
		});
	} 

	onKeyPress(event) {
		if (event.key === 'Enter') {
			this.handlePost()
		}
	}

	handlePost() {
		const response = this.postEmail()
		response.then(res =>{
            // If POST is done successfully.
            if (res.email === undefined) {
                this.setState({
                    success: true,
                })
            // If Error.
            } else {
                let message = res.email[0]
                this.setState({
                    message: message,
                })
            }
		})
	}


    async postEmail() {
		const json = {
			"email": this.state.email,
        }
		const res = await fetch(process.env.REACT_APP_BACKEND_BASE_URL + 'api/password_reset/reset_password/', {
								method: 'POST',
								mode: 'cors',
								credentials: 'same-origin',
								headers: {
                                    'Accept': 'application/json',
									'Content-Type': 'application/json',
								},
								redirect:'follow',
								referrerPlolicy: 'no-referrer',
								body: JSON.stringify(json),
							})
        const response = await res.json()
        console.log(response)
		return response
	}

	getMessage() {
		const message = this.state.message
		switch ( message ) {
			case 'この項目は空にできません。': return <p className={classes.Wrong}>{message}</p>
			case '有効なメールアドレスを入力してください。': return <p className={classes.Wrong}>{message}</p>
			default: return <p>登録したメールアドレスを入力してください。</p>
		}
    }

	render() {
        if (this.state.success === false) {
            return (
                <div className={classes.Root}>
                    {this.getMessage()}
                    <div className={classes.Item}>
                    <TextField className={classes.TextField}
                               value={this.state.email}
                               onKeyPress={this.onKeyPress}
                               onChange={this.handleEmail}
                               label="E-mail" /></div>
                    <div className={classes.Item}>
                        <Button variant="contained"
                                className={classes.Button}
                                color="primary"
                                onKeyPress={this.onKeyPress}
                                onClick={this.handlePost}
                                disableElevation>
                            送信 
                    </Button></div>
                </div>
            )
        } else {
            return (
                <div className={classes.Root}>
                    <div className={classes.MessageSuccess}>パスワード変更メールが正しく送信されました。</div>
                    <div className={classes.MessageSuccess}>1時間以内にパスワードの変更をしてください。</div>
                    <Link to='/login' className={classes.Link}>
                        <Button variant="contained"
                            className={classes.Button}
                            color="primary"
                            disableElevation>
                           戻る 
                        </Button>
                    </Link>
                </div>
            )
        }
	}

}