import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';


const useStyles = makeStyles((theme) => ({
    video: {
        marginTop: theme.spacing(4),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    block: {
        width: theme.spacing(42),
        height: theme.spacing(28),
        marginTop: theme.spacing(4),
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: '#001412',
        color: 'white',
    },
    name: {
        marginTop: theme.spacing(1),
        fontSize: "12px"
    }
}));
export default function VideoFrame(props) {
    const classes = useStyles();
    // eslint-disable-next-line
    return (
        <Card className={classes.block}>
            {/*  eslint-disable-next-line */}
            <iframe src={"https://player.vimeo.com/video/" + props.id}
                frameborder="0" allow="accelerometer;
                autoplay; clipboard-write; encrypted-media;
                gyroscope; picture-in-picture" allowFullScreen className={classes.video}></iframe>
            <p className={classes.name}>{props.name}</p>
        </Card>
    );
}