import React from 'react';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import classes from './fiveQuestion.module.css';

export default class FiveQuestion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            answer: null,
            choices: [false, false, false, false, false],
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        const num = e.target.name[e.target.name.length - 1]
        let target = [false, false, false, false, false]
        target[num - 1] = !this.state.choices[num - 1]

        this.setState({
            answer: e.target.labels[0].innerText, // answer
            choices: target,
        }, () => {

            // data should be returned to Parent components
            let data = {
                answer: null,
                index: this.props.question.order - 1, // Question index
            }

            if (this.props.onChange) {
                // handel  onChange if checkbox is true or false
                if (target[num - 1]) {
                    // checkbox is true
                    data.answer = this.state.answer
                }
                this.props.onChange(data)
            }
        })
    }

    render() {
        return (
            <Paper className={classes.Paper}>
                <div className={classes.InsidePaper}>
                    <p className={classes.QOrder}>問題{this.props.question.order}</p>
                    <p className={classes.QText}>{this.props.question.question_text}</p>
                    <FormControl component="fieldset" className={classes.FormControl}>
                        <FormGroup >
                            <FormControlLabel
                                className={classes.Choice}
                                control={<Checkbox checked={this.state.choices[0]} onChange={this.handleChange} name="choice_1" />}
                                label={this.props.question.choice_1}
                            />
                            <FormControlLabel
                                className={classes.Choice}
                                control={<Checkbox checked={this.state.choices[1]} onChange={this.handleChange} name="choice_2" />}
                                label={this.props.question.choice_2}
                            />
                            <FormControlLabel
                                className={classes.Choice}
                                control={<Checkbox checked={this.state.choices[2]} onChange={this.handleChange} name="choice_3" />}
                                label={this.props.question.choice_3}
                            />
                            <FormControlLabel
                                className={classes.Choice}
                                control={<Checkbox checked={this.state.choices[3]} onChange={this.handleChange} name="choice_4" />}
                                label={this.props.question.choice_4}
                            />
                            <FormControlLabel
                                className={classes.Choice}
                                control={<Checkbox checked={this.state.choices[4]} onChange={this.handleChange} name="choice_5" />}
                                label={this.props.question.choice_5}
                            />
                        </FormGroup>
                    </FormControl>
                </div>
            </Paper>
        );
    }
}