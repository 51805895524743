import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import HomeIcon from '@material-ui/icons/Home';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import YouTubeIcon from '@material-ui/icons/YouTube';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginBottom: '5em',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  link: {
    color: 'black',
    textDecoration: 'none',
  },
  titleLink: {
    color: 'white',
    textDecoration: 'none',
  },
}));


const theme = createMuiTheme(
  {
    palette: {
      primary: {
        main: '#263238',
      },
      secondary: {
        main: '#0288d1',
      },
    },
  }
);


const Logout = () => {
  localStorage.removeItem('token')
}


export default function ButtonAppBar(props) {

  const classes = useStyles(theme);
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <ThemeProvider theme={theme}>
        <AppBar position="fixed" color='primary' className={clsx(classes.appBar, { [classes.appBarShift]: open, })} >
          <Toolbar>
            <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="start" className={clsx(classes.menuButton, open && classes.hide)} >
              <MenuIcon />
            </IconButton>
            <Link to='/' className={classes.titleLink}>
              <Typography variant="h6" noWrap>{process.env.REACT_APP_TITLE}</Typography>
            </Link>
          </Toolbar>
        </AppBar>

        <Drawer className={classes.drawer} variant="persistent" anchor="left" open={open} classes={{ paper: classes.drawerPaper, }} >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>

          <Divider />

          <List>
            <Link to='' className={classes.link}><ListItem button key='Home' onClick={handleDrawerClose}>
              <ListItemIcon><HomeIcon /></ListItemIcon>
              <ListItemText primary='ホーム' />
            </ListItem></Link>
            <Link to='/chapters' className={classes.link}><ListItem button key='Chapter' onClick={handleDrawerClose}>
              <ListItemIcon><BorderColorIcon /></ListItemIcon>
              <ListItemText primary='練習問題' />
            </ListItem></Link>
            <Link to='/playlists' className={classes.link}><ListItem button key='Video' onClick={handleDrawerClose}>
              <ListItemIcon><YouTubeIcon /></ListItemIcon>
              <ListItemText primary='ビデオ' />
            </ListItem></Link>
            <Link to='/tests' className={classes.link}><ListItem button key='Test' onClick={handleDrawerClose}>
              <ListItemIcon><AssignmentTurnedInIcon /></ListItemIcon>
              <ListItemText primary='確認テスト' />
            </ListItem></Link>
            <Link to='/profile' className={classes.link}><ListItem button key='Profile' onClick={handleDrawerClose}>
              <ListItemIcon><AccountBoxIcon /></ListItemIcon>
              <ListItemText primary='プロフィール' />
            </ListItem></Link>
            <Divider />
            <Link to='/login' className={classes.link}><ListItem button key='Logout' onClick={handleDrawerClose}>
              <ListItemIcon><ExitToAppIcon /></ListItemIcon>
              <ListItemText primary='Logout' onClick={Logout} />
            </ListItem></Link>
          </List>
        </Drawer>
      </ThemeProvider>
    </div>
  );
}