import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

const SignupLine = (props) => {
    return (
        <ListItem>
        <ListItemAvatar>
          <Avatar>
            {props.avatar}
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={props.text} />
      </ListItem>
    );
}

export default SignupLine