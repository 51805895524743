import React from 'react';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import EmailIcon from '@material-ui/icons/Email';
import TodayIcon from '@material-ui/icons/Today';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';

import { backend } from '../../hocks/hocks'
import classes from './profile.module.css';
import ProfileLine from './profileLine/profileLine';

class Profile extends React.Component {
    state = {
        user: null,
        avg_score: null,
    }

    componentDidMount() {
        this.fetchProfile();
    }

    fetchProfile() {
        const token = localStorage.getItem('token')
        const username = localStorage.getItem('username')

        if (token === null || token === undefined) {
            localStorage.removeItem('username')
            this.props.history.push('/login')
        }

        backend.get('users/profile/?user__username=' + username  ,
                  {headers: {'Authorization': `JWT ${token}`}})
          .then(res => {
            this.setState({
                user: res.data[0].user,
                avg_score: res.data[0].avg_score
            })
          })
          .catch(err => {
            if (err.response.status === 401) {
                localStorage.removeItem('token')
                localStorage.removeItem('username')
                this.props.history.push('/login')
            }
            console.log(err)
            })
    }

    convertDate(date) {
		if (date === undefined || date === null) {
			return ''
		}
		const chars = date.slice(0,4) + '年' + date.slice(5, 7) + '月' + date.slice(8,) + '日'
		return chars
	}

    handleShowContent() {
        if (this.state.user === null) {
            return null
        } else {
            return (
                <div>
                     <List >
                         <div className={classes.Item}>
                        <ProfileLine text={this.state.user.username}
                                       avatar={<PermContactCalendarIcon />} />
                        <Divider variant="inset" component="li" />
                         </div>
                         <div className={classes.Item}>
                        <ProfileLine text={this.state.user.email}
                                       avatar={<EmailIcon />} />
                        <Divider variant="inset" component="li" />
                         </div>
                         <div className={classes.Item}>
                        <ProfileLine text={this.convertDate(this.state.user.date_of_birth)}
                                       avatar={<TodayIcon />} />
                        <Divider variant="inset" component="li" />
                         </div>
                     </List>
                     <div className={classes.Item}>
                        <Link to='/changePwd'className={classes.Link}><Button variant="contained"
                                className={classes.Button}
                                color="primary"
                                disableElevation>
                            パスワードの変更
                        </Button></Link>
                     </div>
                </div>
            );
        }
    }
   

    render() {
        return (
            <div className={classes.Root}>
                <Paper variant="outlined" className={classes.Paper} >
                    {this.handleShowContent()}
                </Paper>
            </div>
        )
    }
}

export default Profile 