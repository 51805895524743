import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({
  root: {
      width: theme.spacing(40),
      height: theme.spacing(6),
      marginTop: theme.spacing(2),
      margin: theme.spacing(2),
      borderRadius: theme.spacing(1),
    },
    one: {
      backgroundColor: '#03a9f4',
    },
    two: {
      backgroundColor: '#f44336',
    },
    three: {
      backgroundColor: '#33ab9f',
    }
}));

export default function ChoiceBar(props) {
  const classes = useStyles();
  let type_class = null;

  switch (props.type) {
    case '1': type_class = classes.one; break;
    case '2': type_class = classes.two; break;
    case '3': type_class = classes.three; break;
    default: type_class = null
  }

  return (
    <Button variant="contained"
            onClick={props.onClick}
            color="primary"
            className={classes.root + ' ' + type_class}
            disableElevation>
        {props.text}
    </Button>
  );
}