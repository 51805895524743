import React from 'react';
import Paper from '@material-ui/core/Paper';
import classes from './utils.module.css'

export const TitlePaper = (props) => {
    return (
        <Paper className={classes.TitlePaper}>
            <div>{props.children}</div>
        </Paper>
    );
}