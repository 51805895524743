import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { backend } from '../../hocks/hocks';
import classes from './changePwd.module.css';


export default class ChagePwd extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			old: '',
			new: '',
			new2: '',
			user: null,
			message: '',
        }

		this.handleOld = this.handleOld.bind(this);
		this.handleNew = this.handleNew.bind(this);
		this.handleNew2 = this.handleNew2.bind(this);
		this.changePassword = this.changePassword.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.onKeyPress = this.onKeyPress.bind(this);
    }

    componentDidMount() {
        this.fetchProfile()
    }

    fetchProfile() {
        const token = localStorage.getItem('token')
        const username = localStorage.getItem('username')
        if (token === null || token === undefined) {
            localStorage.removeItem('username')
            this.props.history.push('/login')
        }

        backend.get('users/profile/?user__username=' + username ,
                  {headers: {'Authorization': `JWT ${token}`}})
          .then(res => {
            this.setState({
                user: res.data[0].user,
            })
          })
          .catch(err => {
            if (err.response.status === 401) {
                localStorage.removeItem('token')
                localStorage.removeItem('username')
                this.props.history.push('/login')
            }
            console.log(err)
            })
    }


	handleOld(event) {
		this.setState({
			old: event.target.value,
		});
	}


	handleNew(event) {
		this.setState({
			new: event.target.value,
		});
	} 

	handleNew2(event) {
		this.setState({
			new2: event.target.value,
		});
	} 

	onKeyPress(event) {
		if (event.key === 'Enter') {
			this.handleChange()
		}
	}

	handleChange() {
		if (this.state.new !== this.state.new2) {
			this.setState({
                message: '確認用のパスワードが一致しません',
                new2: '',
			})
        } else if (this.state.old === '' || this.state.new === '' || this.state.new2 === '') {
            this.setState({
                message: '全ての項目を埋めてください'
            })
        } else {
			const response = this.changePassword()
			response.then(res =>{
				let message = res.message
				if (message === 'パスワードが間違っています') {
					this.setState({
						message: message,
					})
				}
				if (message === 'Password updated successfully.') {
					this.setState({
						message:'パスワードは正しく変更されました',
					})
				}
			})
		}
	}


    async changePassword() {
		const token = localStorage.getItem('token')
		if (token === null) {
            localStorage.removeItem('username')
            this.props.history.push('/login')
		}

		const json = {
			"old_password": this.state.old,
			"new_password": this.state.new,
			"user": this.state.user.id,
        }
		console.log(json)

		const res = await fetch(process.env.REACT_APP_BACKEND_BASE_URL + 'users/change_password/', {
								method: 'PUT',
								mode: 'cors',
								credentials: 'same-origin',
								headers: {
									'Content-Type': 'application/json',
									'Accept': 'application/json',
									'Authorization': 'JWT ' + token,
								},
								redirect:'follow',
								referrerPlolicy: 'no-referrer',
								body: JSON.stringify(json),
							})
        const response = await res.json()
        console.log(response)
		return response
	}

	getMessage() {
		const message = this.state.message
		switch (message) {
			case 'パスワードが間違っています': return <p className={classes.Wrong}>{message}</p>
			case 'パスワードは正しく変更されました': return <p className={classes.Correct}>パスワードは正しく変更されました</p>
			case '確認用のパスワードが一致しません': return<p className={classes.Wrong}>{message}</p>
			case '全ての項目を埋めてください': return<p className={classes.Wrong}>{message}</p>
			default: return <p>パスワードを入力してください</p>
		}
	}


	render() {
		return (
			<div className={classes.Root}>
				<p className={classes.Message}>{this.getMessage()}</p>
                    <div className={classes.Item}>
                        <TextField className={classes.TextField}
                                    value={this.state.old}
									onChange={this.handleOld}
                                    label="古いパスワード" /></div>
                    <div className={classes.Item}>
                        <TextField className={classes.TextField}
                                    value={this.state.new}
                                    onChange={this.handleNew}
                                    label="新しいパスワード" /></div>
                    <div className={classes.Item}>
                        <TextField className={classes.TextField}
                                    value={this.state.new2}
                                    onChange={this.handleNew2}
									onKeyPress={this.onKeyPress}
                                    label="新しいパスワード(確認)" /></div>
                    <div className={classes.Item}>
                    <Button variant="contained"
                            className={classes.Button}
                            onClick={this.handleChange}
                            color="primary"
                            disableElevation>
                     変更
                    </Button></div>
			</div>
		)
	}

}