import React from 'react';
import { Link } from 'react-router-dom';
import Box, { LockedBox } from './box';
import classes from './box.module.css'

export default class BoxList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            boxes: null,
        }

    }

    createBoxList(lists) {
        let box_list = null
        if (lists != null) {
            box_list = lists.map((item, i) => {
                if (i < this.props.unlockCount || (item.type === '読み' || item.type === '三択' || item.type === 'マルバツ')) {
                    return (
                        <Link to={'/' + this.props.to + '/' + item.id} className={classes.Link}>
                            <Box text={(i + 1) + '. ' + item.text} type={item.type} />
                        </Link>
                    );
                } else {
                    return (
                        <LockedBox text={(i + 1) + '. ' + item.text} type={'default'} />
                    );
                }
            })
        }
        return box_list
    }

    render() {
        return (
            <div>
                {this.createBoxList(this.props.items)}
            </div>
        );
    }
}

export class VideoBoxList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            boxes: null,
        }

    }

    createBoxList(lists) {
        let box_list = null
        if (lists != null) {
            box_list = lists.map((item, i) => {
                if (i < this.props.unlockCount) {
                    return (
                        <Link to={'/' + this.props.to + '/' + item.users + '/' + item.id} className={classes.Link}>
                            <Box text={(i + 1) + '.' + item.text} type='playlist' />
                        </Link>
                    );
                } else {
                    return (
                        <LockedBox text={(i + 1) + '. ' + item.text} type='playlist' />
                    );
                }
            })
        }
        return box_list
    }

    render() {
        return (
            <div>
                {this.createBoxList(this.props.items)}
            </div>
        );
    }
}


export class TestBoxList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            boxes: null,
        }

    }

    createBoxList(lists) {
        let box_list = null
        if (lists != null) {
            box_list = lists.map((item, i) => {
                if (i < this.props.unlockCount) {
                    return (
                        <Link to={'/' + this.props.to + '/' + item.id} className={classes.Link} >
                            <Box text={item.text} type='test' />
                        </Link >
                    );
                } else {
                    return (
                        <LockedBox text={(i + 1) + '. ' + item.text} type={'test'} />
                    );
                }
            })
        }
        return box_list
    }

    render() {
        return (
            <div>
                {this.createBoxList(this.props.items)}
            </div>
        );
    }
}