import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import LockIcon from '@material-ui/icons/Lock';


const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.spacing(40),
    height: theme.spacing(6),
    marginTop: theme.spacing(2),
    borderRadius: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  read: {
    backgroundColor: '#03a9f4',
  },
  tof: {
    backgroundColor: '#f44336',
  },
  three_choice: {
    backgroundColor: '#33ab9f',
  },
  playlist: {
    backgroundColor: '#00838f',
  },
  test: {
    backgroundColor: '#ce1166',
  },
}));

export default function Box(props) {
  const classes = useStyles();
  let type_class = null;
  switch (props.type) {
    case '読み': type_class = classes.read; break;
    case 'マルバツ': type_class = classes.tof; break;
    case '三択': type_class = classes.three_choice; break;
    case 'playlist': type_class = classes.playlist; break;
    case 'test': type_class = classes.test; break;
    default: type_class = null
  }
  return (
    <Button variant="contained" color="primary" className={classes.root + ' ' + type_class} disableElevation>
      {props.text}
    </Button>
  );
}

const useStyleLockedBox = makeStyles((theme) => ({
  root: {
    color: 'white',
    width: theme.spacing(40),
    height: theme.spacing(6),
    marginTop: theme.spacing(2),
    borderRadius: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  playlist: {
    backgroundColor: '#002c30',
  },
  test: {
    backgroundColor: '#35041a',
  },
  default: {
    backgroundColor: '#07233f',
  }
}));

export function LockedBox(props) {
  const classes = useStyleLockedBox();
  let type_class = null;
  switch (props.type) {
    case 'playlist': type_class = classes.playlist; break;
    case 'test': type_class = classes.test; break;
    case 'default': type_class = classes.default; break;
    default: type_class = null
  }
  return (
    <Button variant="contained" className={classes.root + ' ' + type_class} disableElevation>
      <LockIcon style={{ marginRight: '.7rem', }} />
      {props.text}
    </Button>
  );
}