import React from 'react';
import classes from './default/question.module.css';

class Answer extends React.Component {

    render() {
        let tof_style = {
            fontSize: '44px',
        }
        let answer_class = classes.Fail
        if (this.props.answer === this.props.userAnswer) {
            answer_class = classes.Correct
        }
        return (
            <div className={answer_class}>
                <p
                    style={{
                        color: 'black',
                        border: 'solid .1px #eee',
                        padding: '1rem',
                        fontSize: '12px'
                    }}
                    className={classes.Question}>
                    {this.props.question_text}
                </p>
                <div style={{ border: 'solid .1px #eee', margin: '0 1rem 2rem 1rem' }}>
                    <p style={{ color: 'black', fontWeight: 'bold' }}>答え</p>
                    {this.props.tof ? <p className={classes.Question} style={tof_style} >{this.props.answer}</p>
                        : <p className={classes.Question}>{this.props.answer}</p>}
                </div>
            </div >
        );
    }
}

export default Answer;