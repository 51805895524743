import React from 'react';
import Paper from '@material-ui/core/Paper';

import classes from './result.module.css'
import { backend } from '../../hocks/hocks'

export default function  Result(props) {
    return (
        <div className={classes.Root}>
            <Paper variant="outlined" className={classes.Paper} >
                <p>正解率: {props.accuracy}%</p>
                <p>{handleMessage(props.accuracy)}</p>
                <div>{props.children}</div>
            </Paper>
        </div>
    );
}

const handleMessage = (acc) => {
    if (acc === 100) {
        return 'おめでとうございます!満点です!';
    }
    if (acc < 50) {
        return 'もう少しがんばりましょう!';
    }
    if (acc < 80) {
        return 'その調子です!';
    }
}


export async function postLog(questions, answers) {
    await fetchProfile()
    .then(res => {
        for (let i = 0; i < questions.length-1; i++) {
            let data = {
                "user": res.data[0].user.id,
                "answer": answers[i],
                "is_correct": answers[i] === questions[i].question_ans,
                "question": questions[i].id
            }
            fetchLogAPI(data)
        }
    })
} 


const  fetchLogAPI = async (data) => {
    const token = localStorage.getItem('token')
	const res = await fetch('https://back-dot-sakura-kaigo-app.an.r.appspot.com/api/log/', {
					  		method: 'POST',
					  		mode: 'cors',
							credentials: 'same-origin',
							headers: {
								'Content-Type': 'application/json',
						    	'Accept': 'application/json',
								'Authorization': 'JWT ' + token,
							},
							redirect:'follow',
							referrerPlolicy: 'no-referrer',
							body: JSON.stringify(data),
						})
    const response = await res.json()
    console.log(response)
}


const fetchProfile = () => {
    const token = localStorage.getItem('token')
    const username = localStorage.getItem('username')

    return backend.get('users/profile/?user__username=' + username,
              {headers: {'Authorization': `JWT ${token}`}})
}
