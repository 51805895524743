import React from 'react';
import { backend } from '../../hocks/hocks'
import Default from './default/question';
import ThreeChoice from './threeChoice/question'
import TrueOrFalse from './trueOrFalse/question'

export default class Questions extends React.Component {
    state = {
        questions: null,
        type: null,
    }

    componentDidMount() {
        this.fetchQuestions()
    }

    // Handle the question content by question type.
    handleQuestionContent() {
        const type = this.state.type;
        let content = null
        switch (type) {
            case '読み':
                content = <Default questions={this.state.questions} />;
                break;
            case '三択':
                content = <ThreeChoice questions={this.state.questions} />;
                break;
            case 'マルバツ':
                content = <TrueOrFalse questions={this.state.questions} />;
                break;
            default: return null;
        }
        return content;
    }

    fetchQuestions() {
        const token = localStorage.getItem('token')
        if (token === null || token === undefined) {
            localStorage.removeItem('username')
            this.props.history.push('/login')
        }
        backend.get('api/questions/random/?part=' + this.props.match.params.id,
            { headers: { 'Authorization': `JWT ${token}` } })
            .then(res => {
                this.setState({
                    questions: res.data,
                    type: res.data[0].part.question_type.type,
                })
            })
            .catch(err => {
                if (err.response.status === 401) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('username')
                    this.props.history.push('/login')
                }
                console.log(err)
            })
    }

    render() {
        return (
            // <div className={classes.Root}>
            <div>
                {this.handleQuestionContent()}
            </div>
        )
    }
}