import axios from 'axios';


export const backend = axios.create({
    // baseURL: 'https://back-dot-sakura-kaigo-app.an.r.appspot.com/',
    baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
})


export const vimeo = axios.create({
    baseURL:'https://api.vimeo.com/',
})