import React from 'react';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import classes from './question.module.css';
import Result, { postLog } from '../../../components/result/result';
import MobileStepper from '@material-ui/core/MobileStepper';
import Answer from '../answer';

class DefaultQuestion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputs: '',
            answers: [],
            num: 0,
            num_correct: 0,
            is_answer: false,
            is_last: false,
            is_result: false,
        }
        this.handleInputs = this.handleInputs.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handleAgain = this.handleAgain.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.handlePaperContent = this.handlePaperContent.bind(this);
    }

    handleInputs(event) {
        this.setState({ inputs: event.target.value })
    }

    onKeyPress(event) {
        // if keypress is Enter key
        if (event.key === 'Enter') { this.handleNext() }
    }

    handleNext() {
        if (!this.state.is_last) {
            let answer = this.state.answers.slice();
            let is_correct = 0;
            answer.push(this.state.inputs);
            if (this.state.inputs === this.props.questions[this.state.num].question_ans) {
                is_correct = 1
            }
            if (this.state.is_answer) {
                this.setState({
                    is_answer: false,
                })
            } else {
                this.setState({
                    num: this.state.num + 1,
                    answers: answer,
                    is_answer: true,
                    inputs: '',
                    is_last: this.state.num === this.props.questions.length - 2,
                    num_correct: this.state.num_correct + is_correct
                })
            }
        } else {
            postLog(this.props.questions, this.state.answers)
            this.setState({ is_result: true })
        }
    }

    handlePaperContent() {
        if (this.state.is_answer) {
            return <Answer answer={this.props.questions[this.state.num - 1].question_ans}
                userAnswer={this.state.answers[this.state.num - 1]}
                question_text={this.props.questions[this.state.num - 1].question_text}
            />
        } else {
            return (
                <div>
                    <p>{this.props.questions[this.state.num].part.question_type.sentence}</p>
                    <p className={classes.Question}>{this.props.questions[this.state.num].question_text}</p>
                    <TextField className={classes.TextField}
                        value={this.state.inputs}
                        onChange={this.handleInputs}
                        onKeyPress={this.onKeyPress}
                        label="Answer" />
                </div>
            )
        }
    }


    handleButton() {
        if (this.state.is_last) {
            return (
                <Button variant="contained"
                    color="primary"
                    onClick={this.handleNext}
                    disableElevation>
                    Result
                </Button>);
        } else {
            return (
                <Button variant="contained"
                    color="primary"
                    onClick={this.handleNext}
                    disableElevation>
                    次へ
                </Button>);
        }
    }

    progressBar() {
        return (
            <Paper className={classes.WrapperProgressBar}>
                <div className={classes.Center}>
                    <div style={{ paddingTop: '.5rem' }}>
                        {this.state.num + 1} / 10
                    </div>
                    <div className={classes.Emoji}>🏃‍♀</div>
                    <div className={classes.Emoji}>
                        <MobileStepper
                            variant="progress"
                            steps={10}
                            position="static"
                            activeStep={this.state.num}
                            className={classes.ProgressBar}
                        />
                    </div>
                    <div className={classes.Emoji}>🚩</div>
                </div>
            </Paper>
        )
    }


    handleAgain() {
        this.setState({
            nputs: '',
            answers: [],
            num: 0,
            num_correct: 0,
            is_answer: false,
            is_last: false,
            is_result: false,

        })
    }

    render() {
        let content = (
            <Paper variant="outlined" className={classes.Paper} >
                {this.handlePaperContent()}
                <div className={classes.Next}>
                    {this.handleButton()}
                </div>
            </Paper>
        );

        if (this.state.is_result) {
            const accuracy = this.state.num_correct / this.props.questions.length * 100
            content = (
                <Result accuracy={accuracy}>
                    <div className={classes.Buttons}>
                        <Link to='/' className={classes.Link}
                        ><Button
                            className={classes.HomeButton}
                            variant="contained"
                            color="primary"
                            disableElevation>
                                Home
                        </Button></Link>
                        <Button
                            className={classes.AgainButton}
                            variant="contained"
                            color="primary"
                            onClick={this.handleAgain}
                            disableElevation>
                            もう一度
                        </Button>
                    </div>
                </Result>);
        }


        return (
            <div className={classes.Root}>
                {this.progressBar()}
                {content}
            </div>
        );
    }
}

export default DefaultQuestion;