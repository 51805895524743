import React, { useEffect } from 'react';
import { useHistory } from "react-router";
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import testImg from '../../statics/svg/Exams-rafiki.svg';
import qImg from '../../statics/svg/learn.svg';
import videoImg from '../../statics/svg/video.svg';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';;


const homeStyles = makeStyles({
    root: {
        verticalAlign: "middle",
        textAlign: "center",
        justifyContent: "center",
        margin: "auto",
        marginBottom: 80,
    },
});

export default function HomePage() {
    const history = useHistory();
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token === null || token === undefined) {
            localStorage.removeItem('username')
            history.push('/login')
        }
    });

    const classes = homeStyles();
    return (
        <div className={classes.root}>
            <ItemWithLink to="/Chapters"
                name="練習問題"
                class="chapter"
                description="試験対策のための問題を様々な形式で勉強できます!"
                img={qImg}
            />
            <ItemWithLink to="/playlists"
                name="試験対策動画"
                class="playlist"
                description="試験対策の講座を動画で受講できます!"
                img={videoImg}
            />
            <ItemWithLink
                to="/tests"
                name="確認テスト"
                class="test"
                description="動画で学んだ範囲から出題されます。合格すると次の動画が見れます!"
                img={testImg}
            />
            <p><a style={{ textDecoration: 'none', color: 'gray' }} href="https://storyset.com/game">Game illustrations by Storyset</a></p>
        </div>
    )
}


const useStyles = makeStyles({
    root: {
        width: '90%',
        maxWidth: '500px',
        margin: 'auto',
        marginTop: 30,
        background: 'white',
    },
    title: {
        margin: 20,
    },
    description: {
        marginBottom: 12,
        color: "#37474f",
    },
    link: {
        margin: 'auto',
        textDecoration: "none",
    },
    icon: {
        margin: 'auto',
        marginRight: 10,
    },
});


const ItemWithLink = (props) => {
    const classes = useStyles();

    // let type_class = null;
    // switch (props.class) {
    //     case 'chapter': type_class = classes.chapter; break;
    //     case 'playlist': type_class = classes.playlist; break;
    //     case 'test': type_class = classes.test; break;
    //     default: type_class = null
    // }

    return (
        <Card className={classes.root} variant="outlined">
            <CardContent>
                <Typography variant="h6" component="h2" className={classes.title}>
                    {props.name}
                </Typography>
                <img style={{ width: '80%', margin: 'auto' }} src={props.img} />
                <Typography className={classes.description} color="textSecondary">
                    {props.description}
                </Typography>
            </CardContent>
            <CardActions >
                <Link to={props.to} className={classes.link}>
                    <Button size="small" ><ArrowForwardIcon color='primary' /></Button>
                </Link>
            </CardActions>
            {/* </CardActions>
                <CardActions>
                <Link to={props.to} className={classes.link}>
                    <Button size="small"><LinkIcon className={classes.icon} /> Go to page</Button>
                </Link>
            </CardActions> */}
        </Card>
    );
}