import React from 'react';
import { Link } from 'react-router-dom';
import validator from 'validator'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import EmailIcon from '@material-ui/icons/Email';
import FaceIcon from '@material-ui/icons/Face';
import TodayIcon from '@material-ui/icons/Today';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';

import classes from './signup.module.css';
import SignupLine from './signupLine/signupLine';

// localhost:3000/signup/wh*43r6r43ee7lduhy66yo9e3y8zy0shk60w51m3t1/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxLCJ1c2VybmFtZSI6Im96b3JhIiwiZXhwIjoxNjEzMzYzMzE5LCJlbWFpbCI6IiIsIm9yaWdfaWF0IjoxNjEyOTMxMzE5fQ.saczoLDBC53eBsSqolWlSYffbPzDaZDKt-u4Fa_V3vQ/SAKURA

const CASE_CONFIRM = 'confirm'
const CASE_DEFAULT = 'default'
const CASE_SUCCESS = 'success'
const SECRET_KEY = process.env.REACT_APP_SECRET_KEY

export default class Signup extends React.Component {
	constructor(props) {
        super(props);
        if (this.props.match.params.token !== SECRET_KEY) {
            this.props.history.push('/expired')
        }

		this.state = {
            username: '',
            last: '',
            first: '',
            email: '',
            date_of_birth: '',
            password: '',
            password2: '',
            page: CASE_DEFAULT, // Handle the contents to show.
            message: '',
        }

        this.handlePassword = this.handlePassword.bind(this);
        this.handlePassword2 = this.handlePassword2.bind(this);
        this.handleUsername = this.handleUsername.bind(this);
        this.handleLast = this.handleLast.bind(this);
        this.handleFirst = this.handleFirst.bind(this);
        this.handleBirth = this.handleBirth.bind(this);
        this.handleEmail = this.handleEmail.bind(this);
        this.handlePost = this.handlePost.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.postUser = this.postUser.bind(this);
        this.valid_forms = this.valid_forms.bind(this);
        this.confirm = this.confirm.bind(this);
        this.goDefault = this.goDefault.bind(this);
    }
    

    // >>>>>>>>>>>>> Handler >>>>>>>>>>>>>>>>>>

	handleUsername(event) {
		this.setState({
			username: event.target.value,
		});
    } 

	handleLast(event) {
		this.setState({
			last: event.target.value,
		});
    } 
    
	handleFirst(event) {
		this.setState({
			first: event.target.value,
		});
	} 

	handleEmail(event) {
		this.setState({
			email: event.target.value,
		});
	} 

	handleBirth(event) {
		this.setState({
			date_of_birth: event.target.value,
		});
	} 

	handlePassword(event) {
		this.setState({
			password: event.target.value,
		});
	} 

	handlePassword2(event) {
		this.setState({
			password2: event.target.value,
		});
    } 

	onKeyPress(event) {
		if (event.keyCode === 13) {
			this.handlePost()
		}
    }

    // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

    // Push to Signup page.
    goDefault() {
        this.setState({
            page: CASE_DEFAULT,
        })
    }

    // Push to Login page
    goLogin() {
        this.props.history.push('/login');
    }


    // Validate input forms.
    valid_forms() {
        // Validate whether all of inputs are filled.
        if (this.state.username === '' || this.state.last === '' || this.state.first === '' || this.state.date_of_birth === '' || this.state.password === '' || this.state.password2 === '' || this.state.email === '') {
            this.setState({
                message: '全ての情報を入力してください'
            })
            return
        }
        // Validate E-mail.
        if (!validator.isEmail(this.state.email)) {
            this.setState({
                message: '有効なメールアドレスを入力してください'
            })
            return
        }
        // Validate Password length.
        if (this.state.password.length < 8) {
            this.setState({
                message: 'このパスワードは短すぎます。最低 8 文字以上必要です。',
            })
            return
        }
        // Validate Password confirmation.
        if (this.state.password !== this.state.password2) {
            this.setState({
                message: 'パスワードが一致しません'
            })
            return
        }
        return true
    }


    // Handle the behavior when the confirm button is clicked.
    confirm() {
        const status = this.valid_forms() // Validate inputs.
        // If through the validation, route to Confirm page.
        if (status === true) {
            this.setState({
                page: CASE_CONFIRM,
            })
        }
    }




    // Handle the behavior of posting.
	handlePost() {
        const response = this.postUser() // Creating User.
        response.then(res =>{
            console.log(res)
            // If Creating User is successful, route to Success Page.
            if (res.status === 'success') {
                this.setState({
                    page: CASE_SUCCESS,
                })
                return
            // If error...
            } else {
                // If username is already used.
                if (res.message === 'username must be unique.') {
                    this.setState({
                        message: res.message,
                        page: CASE_DEFAULT,
                    })
                    return
                }
            }
        })
	}


    async postUser() {
        // Request body
		const data = {
            "username": this.state.username,
            "last_name": this.state.last,
            "first_name": this.state.first,
            "email": this.state.email,
            "date_of_birth": this.state.date_of_birth,
            "password": this.state.password,
            "school": this.props.match.params.school,
		}

		const res = await fetch(process.env.REACT_APP_BACKEND_BASE_URL + 'users/user/', {
								method: 'POST',
								mode: 'cors',
								credentials: 'same-origin',
								headers: {
                                    'Content-Type': 'application/json',
                                    'Accept': 'application/json',
                                    'Authorization': 'JWT ' + this.props.match.params.jwt
								},
								redirect:'follow',
								referrerPlolicy: 'no-referrer',
								body: JSON.stringify(data),
							})
        const response = await res.json()
		return response
	}


	getMessage() {
		const message = this.state.message
		switch (message) {
			case 'このパスワードは短すぎます。最低 8 文字以上必要です。': return <p className={classes.Wrong}>{message}</p>
			case 'パスワードが一致しません': return <p className={classes.Wrong}>{message}</p>
			case '有効なメールアドレスを入力してください': return <p className={classes.Wrong}>{message}</p>
			case '全ての情報を入力してください': return <p className={classes.Wrong}>{message}</p>
			case 'username must be unique.': return <p className={classes.Wrong}>そのUsernameは既に使われています。</p>
			default: return <p >フォームに情報を入力してください。</p>
		}
    }


    defaultPage() {
        return (
            <div className={classes.Root}>
                <h2 className={classes.Title}>ユーザ登録</h2>
                {this.getMessage()}
                <div className={classes.Item}>
                <TextField className={classes.TextField}
                            value={this.state.username}
                            onKeyPress={this.onKeyPress}
                            onChange={this.handleUsername}
                            label="Username" />
                <div className={classes.Alert}>半角英数字のみ利用可能です</div></div>
                <div className={classes.Item}>
                <TextField className={classes.TextField}
                            value={this.state.last}
                            onKeyPress={this.onKeyPress}
                            onChange={this.handleLast}
                            label="姓" /></div>
                <div className={classes.Item}>
                <TextField className={classes.TextField}
                            value={this.state.first}
                            onKeyPress={this.onKeyPress}
                            onChange={this.handleFirst}
                            label="名" /></div>
                <div className={classes.Item}>
                <TextField className={classes.TextField}
                            type='date'
                            label="生年月日"
                            value={this.state.date_of_birth}
                            onKeyPress={this.onKeyPress}
                            onChange={this.handleBirth}
                            defaultValue="1997-11-27"
                            InputLabelProps={{
                                shrink: true,
                              }}
                             /></div>
                <div className={classes.Item}>
                <TextField className={classes.TextField}
                            value={this.state.email}
                            onKeyPress={this.onKeyPress}
                            onChange={this.handleEmail}
                            label="メールアドレス" /></div>
                <div className={classes.Item}>
                <TextField className={classes.TextField}
                            value={this.state.password}
                            onKeyPress={this.onKeyPress}
                            type="password"
                            onChange={this.handlePassword}
                            label="パスワード" />
                <p className={classes.Alert}>８字以上、半角英数字のみ利用可能です</p></div>
                <div className={classes.Item}>
                <TextField className={classes.TextField}
                            value={this.state.password2}
                            onKeyPress={this.onKeyPress}
                            type="password"
                            onChange={this.handlePassword2}
                            label="パスワード(確認)" /></div>
                <div className={classes.Item}>
                    <Button variant="contained"
                            className={classes.Button}
                            color="primary"
                            onClick={this.confirm}
                            disableElevation>
                        確認に進む 
                </Button></div>
            </div>
        )
    }


    confirmPage() {
        return (
            <div className={classes.Root}>
                <h2 className={classes.Title}>ユーザ登録</h2>
                <List className={classes.List}>
                        <SignupLine text={this.state.username}
                                       avatar={<PermContactCalendarIcon />} />
                        <Divider className={classes.Divider} variant="inset" component="li" />
                        <SignupLine text={this.state.last + ' ' + this.state.first}
                                       avatar={<FaceIcon />} />
                        <Divider className={classes.Divider} variant="inset" component="li" />
                        <SignupLine text={this.state.date_of_birth}
                                       avatar={<TodayIcon />} />
                        <Divider className={classes.Divider} variant="inset" component="li" />
                        <SignupLine text={this.state.email}
                                       avatar={<EmailIcon />} />
                        <Divider className={classes.Divider} variant="inset" component="li" />
                     </List>
                        <Button variant="contained"
                                className={classes.Button}
                                color="primary"
                                onClick={this.goDefault}
                                disableElevation>
                                戻る
                        </Button>
                        <Button variant="contained"
                                className={classes.Button}
                                color="primary"
                                onClick={this.handlePost}
                                disableElevation>
                                登録
                        </Button>
            </div>
        )
    }


    successPage() {
        return (
            <div class='reset-password'>
                <p className={classes.successMessage}>正しく登録されました</p>
                <Link to='/login' className={classes.Link} ><Button variant="contained"
                        className={classes.Button}
                        color="primary"
                        disableElevation>
                        Login
                </Button></Link>
            </div>
        )
    }


	render() {
        switch (this.state.page) {
            case CASE_DEFAULT: return this.defaultPage();
            case CASE_CONFIRM: return this.confirmPage();
            case CASE_SUCCESS: return this.successPage();
            default: return ;
        }
    }
}