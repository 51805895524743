import React from 'react';
import { backend } from '../../hocks/hocks'
import BoxList from '../../components/box/boxList'
import { TitlePaper } from '../../components/utils/utils';


class Parts extends React.Component {
    state = {
        contents: null,
        title: null
    }

    componentDidMount() {
        this.fetchParts()
    }

    fetchParts() {
        const token = localStorage.getItem('token');
        if (token === null || token === undefined) {
            localStorage.removeItem('username')
            this.props.history.push('/login')
        }

        backend.get('api/parts/?chapter=' + this.props.match.params.id,
            { headers: { 'Authorization': `JWT ${token}` } })
            .then(res => {
                const data = res.data.map(item => {
                    return { id: item.id, text: item.part_title, type: item.question_type.type }
                })
                const title = res.data[0].chapter.chapter_title
                this.setState({
                    contents: <BoxList to='questions' items={data} />,
                    title: title
                })
            })
            .catch(err => {
                if (err.response.status === 401) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('username')
                    this.props.history.push('/login')
                }
                console.log(err)
            })
    }

    render() {
        return (
            <div>
                <TitlePaper>

                    👩‍💻 {this.state.title} 👩‍💻
                </TitlePaper>
                {this.state.contents}
            </div>
        )
    }
}

export default Parts