import React from 'react';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import classes from './resetpw.module.css';

export default class ResetPassword extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
            password: '',
            password2: '',
            success: false,
        }
        this.handlePassword = this.handlePassword.bind(this);
        this.handlePassword2 = this.handlePassword2.bind(this);
        this.handlePost = this.handlePost.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.postPassowrd = this.postPassword.bind(this);
        
        // This page need a token which is probided by Password Reset API.
        if (this.props.match.params.token === undefined) {
            this.props.history.push('/login')
        }
	}


	handlePassword(event) {
		this.setState({
			password: event.target.value,
		});
	} 

	handlePassword2(event) {
		this.setState({
			password2: event.target.value,
		});
	} 

	onKeyPress(event) {
		if (event.key === 'Enter') {
			this.handlePost();
		}
	}

	handlePost() {
        // If confirm password input is not same as password input.
        if (this.state.password !== this.state.password2) {
            this.setState({
                message: 'パスワードが一致しません'
            })
        } else {
            const response = this.postPassword() // fetch password reset confirm API
            response.then(res =>{
                console.log(res)
                // If reset was succeeded.
                if (res.password === undefined && res.status === 'OK') {
                    this.setState({
                        success: true,
                    })
                } else {
                // if token is expired or something wrong.
                    if (res.status === 'notfound' || res.token === 'この項目は必須です。') {
                        this.props.history.push('/forgotpw') // Push to forgot password page
                    } else {
                            let message = res.password[0]
                            this.setState({
                                message: message,
                            })
                    }
                }
            })
        }
	}


    async postPassword() {
		const json = {
            "token": this.props.match.params.token,
			"password": this.state.password,
		}

		const res = await fetch(process.env.REACT_APP_BACKEND_BASE_URL + 'api/password_reset/confirm/?token=' + this.props.match.params.token, {
								method: 'POST',
								mode: 'cors',
								credentials: 'same-origin',
								headers: {
									'Content-Type': 'application/json',
								},
								redirect:'follow',
								referrerPlolicy: 'no-referrer',
								body: JSON.stringify(json),
							})
        const response = await res.json();
		return response;
	}

	getMessage() {
		const message = this.state.message
		switch (message) {
			case 'このパスワードは短すぎます。最低 8 文字以上必要です。': return <p className={classes.Wrong}>{message}</p>
			case 'パスワードが一致しません': return <p className={classes.Wrong}>{message}</p>
			case 'このパスワードは ユーザネーム と似すぎています。': return <p className={classes.Wrong}>ユーザネームに似たパスワードは使えません。</p>
			case 'このパスワードは 氏 と似すぎています。': return <p className={classes.Wrong}>名前に似たパスワードは使えません。</p>
			case 'このパスワードは 名 と似すぎています。': return <p className={classes.Wrong}>名前に似たパスワードは使えません。</p>
			case 'このパスワードは メール と似すぎています。': return <p className={classes.Wrong}>メールと似たパスワードは使用できません。</p>
			case 'このパスワードは一般的すぎます。': return <p className={classes.Wrong}>パスワードが単純すぎます。</p>
			default: return <p >新しいパスワードを入力してください</p>
		}
    }
    
    goLogin() {
        this.props.history.push('/login')
    }


	render() {
        if (this.state.success === false) {
            return (
                <div className={classes.Root}>
                    {this.getMessage()}
                    <div className={classes.Items}>
                        <TextField className={classes.TextField}
                                    value={this.state.new}
                                    onChange={this.handlePassword}
                                    label="新しいパスワード" /></div>
                    <div className={classes.Items}>
                        <TextField className={classes.TextField}
                                    value={this.state.new2}
                                    onChange={this.handlePassword2}
									onKeyPress={this.onKeyPress}
                                    label="新しいパスワード(確認)" /></div>
                    <Button variant="contained"
                            className={classes.Button}
                            onClick={this.handlePost}
                            color="primary"
                            disableElevation>
                     送信
                    </Button>
                </div>
            )
        } else {
            return (
                <div className={classes.Root}>
                    <p className={classes.successMessage}>パスワードは正しく更新されました</p>
                    <Link to='/login' className={classes.Link} ><Button variant="contained"
                            className={classes.Button}
                            color="primary"
                            disableElevation>
                            Login
                    </Button></Link>
                </div>
            )
        }
	}

}