import React from 'react';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import classes from './login.module.css'


class LoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: '',
            username: '',
            password: '',
        }

        this.handleLogin = this.handleLogin.bind(this)
        this.onKeyPress = this.onKeyPress.bind(this)
        this.handleUsername = this.handleUsername.bind(this)
        this.handlePassword = this.handlePassword.bind(this)
    }

    handleUsername(event) {
        this.setState({ username: event.target.value })
    }

    handlePassword(event) {
        this.setState({ password: event.target.value })
    }

    onKeyPress(event) {
        // if keypress is Enter key ...
        if (event.key === 'Enter') { this.handleLogin() }
    }

    async handleLogin(event) {
        if (this.state.username.slice(0, 6) === 'signup') {
            this.setState({
                message: '登録用ユーザーではログインできません',
            })
            return
        } else {
            this.getJWT()
                .then(res => {
                    const token = res.token
                    if (token === undefined) {
                        // Process for bad login
                        this.setState({
                            message: 'ユーザネームまたはパスワードが間違っています。',
                            // password: '',
                        })
                    } else {
                        // Process for sucsessfull login
                        localStorage.setItem('token', token)
                        localStorage.setItem('username', this.state.username)
                        this.props.history.push('/')
                    }
                })
        }
    }

    async getJWT() {
        const url = process.env.REACT_APP_BACKEND_BASE_URL + 'api/auth/'
        const body = {
            username: this.state.username,
            password: this.state.password,
        }
        const res = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            redirect: 'follow',
            referrerPlolicy: 'no-referrer',
            body: JSON.stringify(body)
        })
        const json = await res.json()
        return json

    }


    render() {
        return (
            <div className={classes.Root}>
                <Paper variant="outlined" className={classes.Paper} >
                    <p className={classes.Message}>{this.state.message}</p>
                    <div className={classes.Items}>
                        <TextField className={classes.TextField}
                            value={this.state.inputs}
                            onKeyPress={this.onKeyPress}
                            onChange={this.handleUsername}
                            label="Username" />
                    </div>
                    <div className={classes.Items}>
                        <TextField className={classes.TextField}
                            label="Password"
                            onKeyPress={this.onKeyPress}
                            onChange={this.handlePassword}
                            type="password"
                            autoComplete="current-password" />
                    </div>
                    <div className={classes.Items}><Button variant="contained"
                        color="primary"
                        className={classes.root}
                        onClick={this.handleLogin}
                        onKeyPress={this.onKeyPress}
                        disableElevation>
                        Login
                </Button></div>
                </Paper>
                <Link to='forgotpw' className={classes.Link}><p>パスワードを忘れてしまった方はこちら</p></Link>
            </div>
        );
    }
}

export default LoginForm;