import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import classes from './result.module.css';
import Paper from '@material-ui/core/Paper';

export const failPage = (score, border) => {
    return (
        <Paper className={classes.Paper}>
            <p>不合格です</p>
            <p>次は合格できるようにもう一度勉強しましょう</p>
            <p>点数：{score * 100}点</p>
            <p>合格点：{border * 100}点</p>
            <Link to='' className={classes.Link}>
                <Button variant="contained"
                    className={classes.HomeBtn}
                    color="primary"
                    disableElevation>
                    戻る
                    </Button>
            </Link>
        </Paper>
    );
}

export const passPage = (score, border) => {
    return (
        <Paper className={classes.Paper}>
            <p>合格です💮</p>
            <p>おめでとうございます！</p>
            <p>次の科目を勉強できるようになりました！</p>
            <p>点数：{score * 100}点</p>
            <p>合格点：{border * 100}点</p>
            <Link to='' className={classes.Link}>
                <Button variant="contained"
                    className={classes.HomeBtn}
                    color="primary"
                    disableElevation>
                    戻る
                    </Button>
            </Link>
        </Paper>
    );
}