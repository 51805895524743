import React from 'react';
import VideoFrame from '../../components/videoFrame/videoFrame';
import classes from './videos.module.css'
import { vimeo, backend } from '../../hocks/hocks';
import CircularProgress from '@material-ui/core/CircularProgress';

class Videos extends React.Component {

    state = {
        contents: null,
        loading: true,
    }

    componentDidMount() {
        this.fetchPlaylists()
    }

    getIframe(id) {
        // eslint-disable-next-line
        return (<iframe src={"https://player.vimeo.com/video/" + id}
            frameborder="0" allow="accelerometer;
                autoplay; clipboard-write; encrypted-media;
                gyroscope; picture-in-picture" allowFullScreen></iframe>);
    }

    checkAuth() {
        const token = localStorage.getItem('token');
        if (token === null || token === undefined) {
            this.props.history.push('/login')
            localStorage.removeItem('username')
        }
    }

    fetchPlaylists() {
        const key = process.env.REACT_APP_VIMEO_KEY
        this.checkAuth()
        vimeo.get('users/' + this.props.match.params.users + '/projects/' + this.props.match.params.id + '/videos',
            { headers: { 'Authorization': 'bearer ' + key } })
            .then(res => {
                res.data.data.sort((a, b) => {
                    if (a.name > b.name) {
                        return 1
                    } else {
                        return -1
                    }
                })
                // console.log(res.data.data[0].name)
                const data = res.data.data.map(item => {
                    const uri = item.uri.split('/');
                    const id = uri[2]
                    return <VideoFrame id={id} name={item.name} />
                    // return this.getIframe(id)
                })
                this.setState({ contents: data, loading: false })
            })
            .catch(err => {
                console.log(err)
            })
    }

    render() {
        return (
            <div className={classes.Root}>
                {this.state.loading ? <div style={{ paddingTop: '4rem' }}><CircularProgress /></div> : this.state.contents}
            </div>
        )
    }
}

export default Videos